import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

import EditPhotoModal from './EditPhotoModal';
import EditProfileModal from './EditProfileModal';
import { Buffer } from 'buffer';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const UpdateProfileModal: React.FC<SignupModalProps> = ({ isOpen, onClose }) => {

    const User = useSelector((state: any) => state.user);
    const[isEditPhotoModal , SetIsEditPhotoModal] = useState(false);
    const[isEditProfileModal , SetIsEditProfileModal] = useState(false);

    const handleEditPhotoModal = () => {
        onClose();
        SetIsEditPhotoModal(true);
    }
    const handleEditProfileModal = () => {
        onClose();
        SetIsEditProfileModal(true);
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="800px" width="90%" padding="30px">
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full py-10" >
                            <div className="flex flex-col items-center w-full">
                                <div className="flex items-center justify-between" >
                                    <div className="w-[250px] h-[220px] rounded-lg" >
                                    {User?.user?.avatar ? (
                                <img
                                    src={User?.user?.avatar}
                                    alt="User Avatar"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' , borderRadius: "12px" }}
                                    
                                />
                            ) : (
                                <div className="flex items-center h-full" >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="black"
                                    viewBox="0 0 24 24"
                                    width="150px"
                                >
                                    <path d="M12 12c2.7 0 4.8-2.1 4.8-4.8S14.7 2.4 12 2.4 7.2 4.5 7.2 7.2 9.3 12 12 12zm0 2.4c-3.2 0-9.6 1.6-9.6 4.8v2.4h19.2v-2.4c0-3.2-6.4-4.8-9.6-4.8z" />
                                </svg>
                                </div>
                            )}
                                    </div>

                                    {/* user details */}
                                    <div className="mx-14 bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent text-[18px]" >
                                        <h2 className="text-[25px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">{User?.user?.firstName} {User?.user?.lastName}</h2>
                                        <p>{User?.user?.age} years Old</p>
                                        <p>{User?.user?.gender}</p>
                                        <p>Member since {new Date(User?.user?.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}</p>
                                        <div className='flex flex-col' >
                                        <button onClick={handleEditProfileModal} className="mt-4 w-[160px] h-[45px] rounded-[12px] bg-gradient-to-r from-[#507C37] to-[#232323] text-white" >Edit Profile</button>
                                        <button onClick={handleEditPhotoModal} className="mt-4 w-[160px] h-[45px] rounded-[12px] bg-gradient-to-r from-[#507C37] to-[#232323] text-white" >Edit Photo</button>
                                        </div>
                                    </div>
                                </div>

                                {/* details */}
                                <div className='m-10 w-full flex flex-col justify-start' >
                                <div className='bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent my-3' >
                                    {User?.user?.about &&
                                    <>
                                    <h2 className="text-[25px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">About Me</h2>
                                    <p>{User?.user?.about}</p>
                                    </>
                                    }
                                </div>

                                <div className='bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent my-3' >
                                {
                                    User?.user?.reasonOfShape &&
                                    <>
                                    <h2 className="text-[25px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">Why I want to get in shape:</h2>
                                    <p>{ User?.user?.reasonOfShape}</p>   
                                    </>
                                }
                                </div>
                                
                                {
                                     User?.user?.inspiration &&
                                     <>
                                <div className='bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent my-3' >
                                <h2 className="text-[25px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">My Inspirations:</h2>
                                <p>{User?.user?.inspiration.join(", ")}</p>
                                </div>    
                                     </>
                                }
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <EditPhotoModal isOpen={isEditPhotoModal} onClose={() => SetIsEditPhotoModal(false)} />
            <EditProfileModal isOpen={isEditProfileModal} onClose={() => SetIsEditProfileModal(false)} />
        </>
    );
};

export default UpdateProfileModal;
