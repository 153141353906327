import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Box, useBreakpointValue, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody, Button } from '@chakra-ui/react';
import { GetAllChats, CreateChat, UpdateChatName } from 'app/chats/chats.action';
import Logo from '../assets/logo-with-apple.svg';
import { MdWest } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { AiOutlineMinusCircle } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from 'react-redux';
import DeletePopup from './DeletePopup';
import { ClipLoader } from "react-spinners";
import { MdOutlineEditNote } from "react-icons/md";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { MdOutlineCreate } from "react-icons/md";


const FitnessSidebar = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const [delPop, setDelPop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [delId, setDelId] = useState("")
  const [chatEdit, setChatEdit] = useState(false);
  const [inputChat, setInputChat] = useState("");
  const [editChatId, setEditChatId] = useState("");


  const params = useParams();
  const { chatId } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Chats = useSelector((state: any) => state.chats);

  const handleNewChat = async () => {
    setLoading(true);
    await dispatch(GetAllChats() as any)

    if (Chats?.chatList?.length > 0) {
      // const isEmptyChatExist = Chats?.chatList?.some((chat: any) => chat.conversationCount === 0);
      if (Chats?.conversation.length === 0) {
        toast.error("Empty Chat Alredy exist!")
        setLoading(false);
      }
      else {
        await dispatch(CreateChat() as any);
        setLoading(false);

      }
    }
    await dispatch(GetAllChats() as any)
    setLoading(false);

  }

  const handleDeleteChat = async (id: any) => {
    setDelId(id);
    setDelPop(true);
  }

  const handleChatNavigate = (chatId: any) => {
    navigate("/user/chat/" + chatId)
  }

  const handleEditChat = (id: any) => {
    setEditChatId(id);
    setChatEdit(true);
  }

  const updateChat = async (id: any, name: any) => {
    const agentName = Chats?.agentName;
    await dispatch(UpdateChatName(id, name, agentName) as any)
    await setChatEdit(false);
    await dispatch(GetAllChats() as any)
  }

  useEffect(() => {
    if (Chats?.chats?.id) {
      navigate(`/user/chat/${Chats?.chats?.id}`);
    }
  }, [Chats.chats])

  useEffect(() => {

    const handleCreateChat = async () => {
      const data = await dispatch(GetAllChats() as any)
      if (data.payload[0].id) {
        navigate(`/user/chat/${data.payload[0].id}`)
      }
    }

    handleCreateChat()
  }, [])

  // useEffect(() => {

  //   const handleChat = async () => {
  //     if(Chats.chatList.length === 0){
  //     await dispatch(CreateChat() as any);
  //     navigate(`/chat/${Chats?.chatList[0]?.id}`)
  //     }
  //   }

  //   handleChat();
  // },[Chats.chatList])

  return isDesktop ? (
    // For larger screens, show the sidebar permanently
    <>
      <DeletePopup delPop={delPop} setDelPop={setDelPop} delId={delId} />
      <Box
        as="aside"
        w="300px"
        bg="#202123"
        color="white"
        p={4}
        h="100vh"
      >
        {/* Sidebar content */}
        <Box fontWeight="bold" mb={4}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <img src={Logo} alt="live100-logo" className='w-[150px]' />
            <Box display="flex" alignItems="center">
              <MdWest className='mr-1' />
              Back
            </Box>
          </Box>
          <Box margin="20px 0">
            <Button onClick={handleNewChat} display="flex" alignItems="center" justifyContent="start" fontWeight="600" borderRadius="12px" width="100%" height="59px"> <MdAdd className='mr-2' />
              {
                loading ?
                  <ClipLoader color="black" size={22} />
                  :
                  "New Chat"
              }
            </Button>
          </Box>

          <Box height="600px" overflowY="auto" >
            <Box margin="20px 0" >
              <p>Today</p>
              <Box fontWeight="400" padding="5px 0" >
                {
                  Chats?.chatList?.map((chat: any, index: any) => {
                    return (
                      <>
                        <div onClick={() => handleChatNavigate(chat.id)} className={`flex items-center justify-between cursor-pointer rounded-lg my-5 py-4 px-3 ${chat.id === chatId && "bg-gray-600"}`} >
                          {/* <small key={index} >{chat?.chatName}</small> */}
                          {
                            (chatEdit && editChatId === chat.id) ?
                              <>
                                <input type="text" onChange={(e) => setInputChat(e.target.value)} value={inputChat} className="bg-transparent w-[90%] py-1 px-3 border-none outline outline-slate-100 rounded" />
                              </>
                              :
                              <>
                                <p className="flex flex-col" >
                                  {chat.chatName}
                                </p>
                              </>
                          }
                          <div className='flex items-center' >
                            {
                              chatEdit && chat.id === chatId ?
                                <AiOutlineCheckSquare className="text-[20px] mx-1" onClick={() => updateChat(chat.id, inputChat)} />
                                :
                                <>
                                  {
                                    chat.id === chatId && <MdOutlineCreate className="text-[20px] mx-1" onClick={() => handleEditChat(chat.id)} />
                                  }
                                </>
                            }
                            {chat.id === chatId && <AiOutlineMinusCircle className='text-[18px] ml-3' onClick={() => handleDeleteChat(chat.id)} />}
                          </div>
                        </div>
                      </>
                    )
                  })
                }
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Add sidebar items */}
      </Box>
    </>
  ) : (
    // For smaller screens, use Chakra's Drawer component
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg="gray.900" color="white">
        <DrawerCloseButton />
        <DrawerBody p={4}>
          <Box fontWeight="bold" mb={4}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <img src={Logo} alt="live100-logo" className='w-[150px]' />
              <Box display="flex" alignItems="center">
                <MdWest className='mr-1' />
                Back
              </Box>
            </Box>
            <Box margin="20px 0">
              <Button display="flex" alignItems="center" justifyContent="start" fontWeight="600" borderRadius="12px" width="100%" height="59px"> <MdAdd className='mr-2' /> New chat</Button>
            </Box>
          </Box>
          {/* Add sidebar items */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FitnessSidebar;
