import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UserNavbar from "./UserNavbar";
import WaterGlass from '../assets/water-glass.png'
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { MdCheck } from "react-icons/md";
import { MdOutlineCreate } from "react-icons/md";
import PremiumImg from '../assets/premium.png'

const FoodDiary = () => {
    return (
        <>
            <div className="bg-[#F5F5F5]" >
                <UserNavbar />

                {/* food diary */}
                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between my-3">
                    <div className="w-[70%]">
                        <div className="flex items-center" >
                            <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent mr-5 font-semibold" >Your Food Diary For</p>
                            <div className="flex items-center" >
                                <div className="mr-2" >
                                    <MdOutlineArrowBackIos className="bg-gradient-to-r from-[#507C37] to-[#232323] text-[25px] text-white p-1" />
                                </div>
                                <p className="bg-gradient-to-r from-[#507C37] to-[#232323] text-white px-2" >date</p>
                                <div className="ml-2" >
                                    <MdArrowForwardIos className="bg-gradient-to-r from-[#507C37] to-[#232323] text-[25px] text-white p-1" />
                                </div>
                            </div>
                        </div>
                        <p className="w-full h-[8px] bg-gray-400 my-1"></p>

                        <div className="flex justify-end w-[100%] mt-3">
                            <div className="w-[65%]" >
                                <table className="w-full border-separate border-spacing-1">
                                    <thead className="w-full" >
                                        <tr>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] font-medium text-white w-[100px]" >
                                                <div>
                                                    <p>Calories</p>
                                                    <small>kcal</small>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium w-[100px]" >
                                                <div>
                                                    <p>Carbs</p>
                                                    <small>g</small>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] font-medium text-white w-[100px]" >
                                                <div>
                                                    <p>Fat</p>
                                                    <small>g</small>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] font-medium text-white w-[100px]" >
                                                <div>
                                                    <p>Protein</p>
                                                    <small>g</small>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium w-[100px]" >
                                                <div>
                                                    <p>Sodium</p>
                                                    <small>mg</small>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium w-[100px]" >
                                                <div>
                                                    <p>Sugar</p>
                                                    <small>g</small>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                        <div className="flex items-start justify-between" >
                            <div className="w-[30%]">
                                <div className="flex flex-col" >
                                    <div className="pb-3 border-b border-b-[4px] my-2 font-semibold" >
                                        <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Breakfast</p>
                                        <div className="flex items-center" >
                                            <small className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >add food</small>
                                            <small className="mx-4 bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >quick tools</small>
                                        </div>
                                    </div>
                                    <div className="pb-3 border-b border-b-[4px] my-2 font-semibold" >
                                        <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Lunch</p>
                                        <div className="flex items-center" >
                                            <small className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >add food</small>
                                            <small className="mx-4 bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >quick tools</small>
                                        </div>
                                    </div>
                                    <div className="pb-3 border-b border-b-[4px] my-2 font-semibold" >
                                        <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Dinner</p>
                                        <div className="flex items-center" >
                                            <small className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >add food</small>
                                            <small className="mx-4 bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >quick tools</small>
                                        </div>
                                    </div>
                                    <div className="pb-3 border-b border-b-[4px] my-2 font-semibold" >
                                        <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Breakfast</p>
                                        <div className="flex items-center" >
                                            <small className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >add food</small>
                                            <small className="mx-4 bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >quick tools</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-end w-[100%]">
                            <div className="w-[65%]" >
                                <table className="w-full border-separate border-spacing-1">
                                    <tbody className="w-full" >
                                        <tr>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-end mt-4" >
                                <div className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium mx-1 px-2 py-1 text-center border-b-2 border-[#C2C2C2] w-[70px] text-sm" >
                                    <p>Calories</p>
                                    <small>kcal</small>
                                </div>
                                <div className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium mx-1 px-2 py-1 text-center border-b-2 border-[#C2C2C2] w-[70px] text-sm" >
                                    <p>Carbs</p>
                                    <small>g</small>
                                </div>
                                <div className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium mx-1 px-2 py-1 text-center border-b-2 border-[#C2C2C2] w-[70px] text-sm" >
                                    <p>Fat</p>
                                    <small>g</small>
                                </div>
                                <div className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium mx-1 px-2 py-1 text-center border-b-2 border-[#C2C2C2] w-[70px] text-sm" >
                                    <p>Protein</p>
                                    <small>g</small>
                                </div>
                                <div className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium mx-1 px-2 py-1 text-center border-b-2 border-[#C2C2C2] w-[70px] text-sm">
                                    <p>Sodium</p>
                                    <small>mg</small>
                                </div>
                                <div className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium mx-1 px-2 py-1 text-center border-b-2 border-[#C2C2C2] w-[70px] text-sm" >
                                    <p>Sugar</p>
                                    <small>g</small>
                                </div>
                            </div>
                        </div>

                        <div className="text-center flex flex-col items-center text-white my-5" >
                            <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent font-semibold">When you’re finished logging all foods and exercise for this day, click here:</p>
                            <button className="flex justify-center items-center text-white my-6 bg-gradient-to-l from-[#507C37] to-[#232323] w-[234px] h-[59px] rounded-[12px]"> <MdCheck className="text-[20px] text-white mr-3" /> Complete This Entry</button>
                        </div>

                    </div>
                    <div className="w-[25%] bg-gradient-to-l from-[#507C37] to-[#232323] text-white flex flex-col items-center justify-center" >
                        <div className="flex items-center ">
                            <img src={PremiumImg} alt="" />
                            <div className="text-[20px] ml-3" >PREMIUM</div>
                        </div>
                        <div className="text-[60px] px-5 text-center font-bold leading-[80px] drop-shadow-lg" >Hit your macro goals</div>
                            <div className="text-[18px] px-5 text-center my-5" >Find your ideal balance of carbs, fat, and protein.</div>
                            <button className="w-[270px] h-[46px] rounded-[12px] text-[#253200] bg-white font-medium" >GO PREMIUM</button>
                    </div>
                </section>

                <div className="w-[62%] mx-[85px] border-2 border-[#8a8a8a] p-2 my-5" >
                    <div className="flex justify-between items-start" >
                        <div className="w-full flex flex-col items-start p-2 w-[42%]" >
                            <h6 className="text-[20px] my-2 font-semibold">Water consumption</h6>
                            <p className=" font-semibold" >Today's Water Total</p>
                            <p className="text-[#696969] text-[14px]" >Aim to drink at least 1920 milli liters of water today.You can quick add common sizes or enter a custom amount.</p>
                            <p className="my-3 text-[#0054FF]" >change units</p>

                            <div className="my-3" >
                                <p className="font-semibold" >Quick Add</p>
                                <div className="flex items-center text-[#0054FF]" >
                                    <p>+250 ml</p>
                                    <p className="mx-4" >+500 ml</p>
                                    <p>+100 ml</p>
                                </div>
                            </div>

                            <div className="my-2" >
                                <p className="font-semibold" >Add Custom</p>
                                <div className="flex items-center text-[#0054FF]" >
                                    <p>+250 ml</p>
                                    <p className="mx-4" >+500 ml</p>
                                    <p>+100 ml</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-[20%] text-center" >
                            <p>0 ml edit <MdOutlineCreate className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent"  /></p>
                            <img src={WaterGlass} alt="" className="mx-auto" />
                        </div>
                        <div className="w-[35%]" >
                            <div className="flex justify-between" >
                                <p>Today's Food Notes</p>
                                <p>edit note (icon)</p>
                            </div>
                            <div className="w-[306px] h-[153px] bg-[#D9D9D9] mt-4" >

                            </div>
                        </div>
                    </div>
                    <div className="flex items-center" >
                        <input type="text" placeholder="Enter ml" className="bg-white px-4 py-2 border-2 border-[#E2E2E2] rounded-[8px]" />
                        <button className="mx-4 px-4 py-2 flex items-center bg-gradient-to-l from-[#507C37] to-[#232323] text-white rounded-[6px]" > <MdAdd className="mr-1 text-[18px]" /> Add</button>
                    </div>
                </div>

                <div className="flex items-center justify-center my-5" >
                    <button className="bg-gradient-to-l from-[#507C37] to-[#232323] text-white w-[250px] h-[39px] font-medium rounded-[6px]" >View Full Report (Printable)</button>
                </div>

                <footer className="bg-white py-10" >
                    <div className="flex items-center justify-center flex-wrap sm:w-[90%] md:w-[65%] text-[#0054FF] mx-auto" >
                       <Link to="" className="mx-2">About Us</Link>
                                               <Link to="" className="mx-2">Privacy</Link>
                                               <Link to="" className="mx-2">Terms Of Use</Link>
                                               <Link to="" className="mx-2">Contact Us</Link>
                                               <Link to="" className="mx-2">Write a Feedback</Link>
                    </div>

                    <div className="mt-4" >
                        <p className="text-center text-[#000]" >© 2024 Live100, Inc.</p>
                    </div>

                </footer>
            </div>
        </>
    )
}

export default FoodDiary;