import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UserNavbar from "./UserNavbar";
import WaterGlass from '../assets/water-glass.png'
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { MdCheck } from "react-icons/md";
import { MdOutlineCreate } from "react-icons/md";
import PremiumImg from '../assets/premium.png'
import { FaPen } from "react-icons/fa";
import { MdOutlinePrint } from "react-icons/md";
import { MdOutlineSearch } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import { Box, Flex, Button} from '@chakra-ui/react';
import { FaRegTrashAlt } from "react-icons/fa";


const AddFood = () => {
    return (
        <>
            <div className="bg-[#F5F5F5]" >
                <UserNavbar />

                {/* food diary */}
                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between my-3">
                    <div className="w-[70%]">
                        <div className="flex flex-col justify-center" >
                            <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent mr-5 font-semibold" >Add Food To Breakfast</p>
                            <div className="flex items-center mt-8" >
                                <p className="text-[18px] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent mr-5 font-semibold" >Search our food database by name</p>
                                <p className="text-[#0054FF] flex items-center" ><MdAdd className="mr-2" /> Quick add calories</p>
                            </div>
                            <div className="flex items-center mt-8" >
                                <div className="relative" >
                                    <input type="text" placeholder="Search food" className="w-[467px] h-[58px] outline-none px-10 border-2 border-[#E2E2E2] rounded-[8px]" />
                                    <MdOutlineSearch className="absolute bottom-[15px] text-[25px] left-[10px] text-[#33363F]" />
                                </div>
                                <button className="w-[141px] h-[59px] ml-3 rounded-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Search</button>
                            </div>

                            <div className="mt-8 flex items-center" >
                                <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent mr-1 font-semibold" >Or, add your favorites for:</p>
                                <Menu>
                                    <MenuButton background="transparent" _focus={{ background: 'transparent' }} _hover={{ background: 'transparent' }} as={Button}>
                                        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
                                            <Box margin="0 14px" >
                                                <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent flex items-center" >Level 1 <MdKeyboardArrowDown className="text-[22px] ml-1 text-black" /> </p>
                                            </Box>
                                        </Flex>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem fontWeight="600" >Profile</MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>

                            <div className="flex items-center justify-between mt-6" >
                            <button className="w-[141px] h-[59px] ml-3 rounded-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Add Checked</button>
                            <div className="w-[70%] flex items-center" >
                            <button className="w-[141px] h-[59px] ml-3 rounded-t-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Add Checked</button>
                            <button className="w-[141px] h-[59px] ml-3 rounded-t-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Add Checked</button>
                            <button className="w-[141px] h-[59px] ml-3 rounded-t-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Add Checked</button>
                            <button className="w-[141px] h-[59px] ml-3 rounded-t-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Add Checked</button>
                            <button className="w-[141px] h-[59px] ml-3 rounded-t-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Add Checked</button>
                            </div>
                            </div>
                        </div>

                        <div className="mt-5" >
                        {/* item */}
                        <div className="flex items-center justify-between bg-[#EDEDED] border-b-2 border-t-2 border-[#C8C8C8] py-2 px-3" >
                            <div className="flex items-center" >
                            <input type="checkbox" name="" id="" className="w-[20px] h-[20px] mr-2" />
                            <p>Milk, low fat, 1%</p>
                            </div>

                            <div className="flex items-center justify-between" >
                            {/* quantity */}
                            <div className="flex items-center font-medium" >
                            <p>Qty: </p>
                            <input type="text" className="w-[84px] h-[40px] rounded-[8px] bg-white mx-4 outline-none px-3" />
                            </div>
                            of
                             {/* cups */}
                             <div className="flex items-center" >
                             <Menu>
                                    <MenuButton background="transparent" _focus={{ background: 'transparent' }} _hover={{ background: 'transparent' }} as={Button}>
                                        <Flex display={{ base: 'none', md: 'flex' }}>
                                            <Box margin="0 14px" >
                                                <p className="flex items-center justify-between px-3 bg-white rounded-[8px]  w-[240px] h-[40px]" >Level 1 <MdKeyboardArrowDown className="text-[22px] ml-1 text-black" /> </p>
                                            </Box>
                                        </Flex>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem fontWeight="600" >Profile</MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>
                            </div>
                        </div>
                        {/* item */}
                        <div className="flex items-center justify-between bg-[#EDEDED] border-b-2 border-[#C8C8C8] py-2 px-3" >
                            <div className="flex items-center" >
                            <input type="checkbox" name="" id="" className="w-[20px] h-[20px] mr-2" />
                            <p>Homemade Hotdog - Hotdog</p>
                            </div>

                            <div className="flex items-center justify-between" >
                            {/* quantity */}
                            <div className="flex items-center font-medium" >
                            <p>Qty: </p>
                            <input type="text" className="w-[84px] h-[40px] rounded-[8px] bg-white mx-4 outline-none px-3" />
                            </div>
                            of
                             {/* cups */}
                             <div className="flex items-center" >
                             <Menu>
                                    <MenuButton background="transparent" _focus={{ background: 'transparent' }} _hover={{ background: 'transparent' }} as={Button}>
                                        <Flex display={{ base: 'none', md: 'flex' }}>
                                            <Box margin="0 14px" >
                                                <p className="flex items-center justify-between px-3 bg-white rounded-[8px]  w-[240px] h-[40px]" >Level 1 <MdKeyboardArrowDown className="text-[22px] ml-1 text-black" /> </p>
                                            </Box>
                                        </Flex>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem fontWeight="600" >Profile</MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>
                            </div>
                        </div>
                        {/* item */}
                        <div className="flex items-center justify-between bg-[#EDEDED] border-b-2 border-[#C8C8C8] py-2 px-3" >
                            <div className="flex items-center" >
                            <input type="checkbox" name="" id="" className="w-[20px] h-[20px] mr-2" />
                            <p>White Jasmine Rice (Cooked - White Rice (Cooked)</p>
                            </div>

                            <div className="flex items-center justify-between" >
                            {/* quantity */}
                            <div className="flex items-center font-medium" >
                            <p>Qty: </p>
                            <input type="text" className="w-[84px] h-[40px] rounded-[8px] bg-white mx-4 outline-none px-3" />
                            </div>
                            of
                             {/* cups */}
                             <div className="flex items-center" >
                             <Menu>
                                    <MenuButton background="transparent" _focus={{ background: 'transparent' }} _hover={{ background: 'transparent' }} as={Button}>
                                        <Flex display={{ base: 'none', md: 'flex' }}>
                                            <Box margin="0 14px" >
                                                <p className="flex items-center justify-between px-3 bg-white rounded-[8px]  w-[240px] h-[40px]" >Level 1 <MdKeyboardArrowDown className="text-[22px] ml-1 text-black" /> </p>
                                            </Box>
                                        </Flex>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem fontWeight="600" >Profile</MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>
                            </div>
                        </div>
                        {/* item */}
                        <div className="flex items-center justify-between bg-[#EDEDED] border-b-2 border-[#C8C8C8] py-2 px-3" >
                            <div className="flex items-center" >
                            <input type="checkbox" name="" id="" className="w-[20px] h-[20px] mr-2" />
                            <p>Boiled eggs</p>
                            </div>

                            <div className="flex items-center justify-between" >
                            {/* quantity */}
                            <div className="flex items-center font-medium" >
                            <p>Qty: </p>
                            <input type="text" className="w-[84px] h-[40px] rounded-[8px] bg-white mx-4 outline-none px-3" />
                            </div>
                            of
                             {/* cups */}
                             <div className="flex items-center" >
                             <Menu>
                                    <MenuButton background="transparent" _focus={{ background: 'transparent' }} _hover={{ background: 'transparent' }} as={Button}>
                                        <Flex display={{ base: 'none', md: 'flex' }}>
                                            <Box margin="0 14px" >
                                                <p className="flex items-center justify-between bg-white rounded-[8px]  w-[240px] h-[40px] px-3" >Level 1 <MdKeyboardArrowDown className="text-[22px] ml-1 text-black" /> </p>
                                            </Box>
                                        </Flex>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem fontWeight="600" >Profile</MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                        <MenuItem fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                                            Billings
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div className="flex justify-between w-[100%] mt-8 pb-2">
                            <div className="font-semibold flex justify" >
                            <button className="w-[141px] h-[59px] ml-3 rounded-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Add Checked</button>
                            </div>

                            <div className="w-[45%]" >
                                <table className="w-full border-separate border-spacing-1">
                                    <thead className="w-full" >
                                        <tr>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] font-medium text-white w-[197px] h-[52px] rounded-[6px]" >
                                                <div>
                                                    <p className="flex items-center justify-center" > <FaRegTrashAlt className="text-[22px] text-white mr-2" /> DELETE FROM LIST</p>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium w-[197px] h-[52px] rounded-[6px] " >
                                                <div>
                                                    <p>VIEW DELETED ITEMS</p>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <i>Note: Checked items from all tabs will be added</i>
                    </div>

                    <div className="w-[25%] bg-gradient-to-l from-[#507C37] to-[#232323] text-white flex flex-col items-center justify-center min-h-[600px]" >
                        <div className="flex items-center ">
                            <img src={PremiumImg} alt="" />
                            <div className="text-[20px] ml-3" >PREMIUM</div>
                        </div>
                        <div className="text-[60px] px-5 text-center font-bold leading-[80px] drop-shadow-lg" >Hit your macro goals</div>
                        <div className="text-[18px] px-5 text-center my-5" >Find your ideal balance of carbs, fat, and protein.</div>
                        <button className="w-[270px] h-[46px] rounded-[12px] text-[#253200] bg-white font-medium" >GO PREMIUM</button>
                    </div>
                </section>

                <footer className="bg-white py-10 mt-[100px]" >
                    <div className="flex items-center justify-center flex-wrap sm:w-[90%] md:w-[65%] text-[#0054FF] mx-auto" >
                       <Link to="" className="mx-2">About Us</Link>
                                               <Link to="" className="mx-2">Privacy</Link>
                                               <Link to="" className="mx-2">Terms Of Use</Link>
                                               <Link to="" className="mx-2">Contact Us</Link>
                                               <Link to="" className="mx-2">Write a Feedback</Link>
                    </div>

                    <div className="mt-4" >
                        <p className="text-center text-[#000]" >© 2024 Live100, Inc.</p>
                    </div>

                </footer>
            </div>
        </>
    )
}

export default AddFood;