import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UserNavbar from "./UserNavbar";
import WaterGlass from '../assets/water-glass.png'
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { MdCheck } from "react-icons/md";
import { MdOutlineCreate } from "react-icons/md";
import PremiumImg from '../assets/premium.png'
import { FaPen } from "react-icons/fa";
import { MdOutlinePrint } from "react-icons/md";
import { MdOutlineSearch } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import { Box, Flex, Button} from '@chakra-ui/react';
import { FaRegTrashAlt } from "react-icons/fa";


const AddExercise = () => {
    return (
        <>
            <div className="bg-[#F5F5F5]" >
                <UserNavbar />

                {/* food diary */}
                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between my-3">
                    <div className="w-[70%]">
                        <div className="flex flex-col justify-center" >
                            <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent mr-5 font-semibold" >Add Exercise</p>
                            <div className="flex items-center mt-8" >
                                <p className="text-[18px] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent mr-5 font-semibold" >Search our exercise database by name</p>
                            </div>
                            <div className="flex items-center mt-8" >
                                <div className="relative" >
                                    <input type="text" placeholder="Search food" className="w-[467px] h-[58px] outline-none px-10 border-2 border-[#E2E2E2] rounded-[8px]" />
                                    <MdOutlineSearch className="absolute bottom-[15px] text-[25px] left-[10px] text-[#33363F]" />
                                </div>
                                <button className="w-[141px] h-[59px] ml-3 rounded-[8px] bg-gradient-to-l from-[#507C37] to-[#232323] font-medium text-white">Search</button>
                            </div>

                            <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent font-medium" >Or, add your most recently used cardio exercise</p>
                        </div>

                        <div className="w-full flex items-center justify-end mt-10" >
                        <p>sort by: </p>
                        <div className="mx-6 flex items-center" >
                            <p>Most Recent</p>
                            <input type="checkbox" name="" id="" className="ml-2 w-[20px] h-[20px] rounded-full" />
                        </div>
                        <div className="flex items-center">
                            <p>Name</p>
                            <input type="checkbox" name="" id="" className="ml-2 w-[20px] h-[20px] rounded-full" />
                        </div>
                        </div>

                        <div className="bg-[#EDEDED] border-b-2 border-t-2 border-[#C8C8C8] px-5 py-2 mt-8" >
                        <p>You have not added any cardio exercises yet.</p>
                        <p className="mt-4" ><span className="font-semibold" >TIP:</span> As you add entries to your exercise diary, the exercises you've performed most recently will appear in this list so that you can quickly add them to your diary</p>
                        </div>


                      
                    </div>

                    <div className="w-[25%] bg-gradient-to-l from-[#507C37] to-[#232323] text-white flex flex-col items-center justify-center min-h-[600px]" >
                        <div className="flex items-center ">
                            <img src={PremiumImg} alt="" />
                            <div className="text-[20px] ml-3" >PREMIUM</div>
                        </div>
                        <div className="text-[60px] px-5 text-center font-bold leading-[80px] drop-shadow-lg" >Hit your macro goals</div>
                        <div className="text-[18px] px-5 text-center my-5" >Find your ideal balance of carbs, fat, and protein.</div>
                        <button className="w-[270px] h-[46px] rounded-[12px] text-[#253200] bg-white font-medium" >GO PREMIUM</button>
                    </div>
                </section>

                <footer className="bg-white py-10 mt-[100px]" >
                    <div className="flex items-center justify-center flex-wrap sm:w-[90%] md:w-[65%] text-[#0054FF] mx-auto" >
                        <Link to="" className="mx-2">About Us</Link>
                                                <Link to="" className="mx-2">Privacy</Link>
                                                <Link to="" className="mx-2">Terms Of Use</Link>
                                                <Link to="" className="mx-2">Contact Us</Link>
                                                <Link to="" className="mx-2">Write a Feedback</Link>
                    </div>

                    <div className="mt-4" >
                        <p className="text-center text-[#000]" >© 2024 Live100, Inc.</p>
                    </div>

                </footer>
            </div>
        </>
    )
}

export default AddExercise;