import React from 'react';

const LinearProgress = ({
  value,
  pathColor,
  score,
  text,
  width,
  height,
  gradient,
}: {
  value: number;
  pathColor: string;
  score: string;
  text: string;
  width: number;
  height: number;
  gradient?: string;
}) => {
  return (
    <div style={{ position: 'relative', width: `${width}px` }}>
      <div
        style={{
          height: `${height}px`,
          backgroundColor: '#B9B9B9',
          borderRadius: '15px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: `${value}%`,
            height: '100%',
            background: "linear-gradient(90deg, #232323, #507C37)",
            transition: 'width 0.3s ease',
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          color: gradient || '#fff',
        }}
      >
        {/* <div className="text-[20px] font-bold">{score}</div>
        <div style={{ fontSize: '12px', fontWeight: 'normal' }}>{text}</div> */}
      </div>
    </div>
  );
};

export default LinearProgress;
