import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import toast from 'react-hot-toast';

import liveLogo from '../assets/live100_logo.svg';
import { UpdateProfile } from 'app/user/user.action';
import { ClipLoader } from "react-spinners";
import { Buffer } from 'buffer';
import { GetUser } from 'app/user/user.action';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const EditPhotoModal: React.FC<SignupModalProps> = ({ isOpen, onClose }) => {

    const dispatch = useDispatch();
    const User = useSelector((state: any) => state.user);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            toast.error('Please select an image to upload.');
            return;
        }
    
        const reader = new FileReader();
    
        reader.onload = async () => {
            if (reader.result) {
                // Convert the file data to a base64 string
                const base64String = reader.result.toString(); 
                console.log("Base64 String: ", base64String);
    
                try {
                    await dispatch(UpdateProfile({ avatar: base64String }));
                    toast.success('Photo uploaded successfully!');
                    await dispatch(GetUser() as any);
                } catch (error) {
                    console.error("Failed to upload the photo: ", error);
                    toast.error('Failed to upload the photo.');
                }
            }
        };
    
        reader.onerror = () => {
            toast.error('Failed to process the file.');
        };
    
        // Read the file as a data URL to get base64
        reader.readAsDataURL(selectedFile); 
    };


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="850px" width="90%" padding="30px">
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full py-10" >
                            <div className="flex flex-col items-center w-full">
                                <div className="flex items-center justify-between" >
                                    <div className="w-[350px] h-[220px] rounded-lg" >
                                    {User?.user?.avatar ? (
                                <img
                                    src={User?.user?.avatar}
                                    alt="User Avatar"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' , borderRadius: "12px" }}
                                    
                                />
                            ) : (
                                <div className="flex items-center h-full" >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="black"
                                    viewBox="0 0 24 24"
                                    width="150px"
                                >
                                    <path d="M12 12c2.7 0 4.8-2.1 4.8-4.8S14.7 2.4 12 2.4 7.2 4.5 7.2 7.2 9.3 12 12 12zm0 2.4c-3.2 0-9.6 1.6-9.6 4.8v2.4h19.2v-2.4c0-3.2-6.4-4.8-9.6-4.8z" />
                                </svg>
                                </div>
                            )}
                                    </div>

                                    {/* user details */}
                                    <div className="mx-10 bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                        <h2 className="text-[20px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">Add a new photo*</h2>
                                        <p>To add a photo to your profile, first choose a photo from your computer, then click "Upload Photo"</p>
                                        <div className='my-3' >
                                            <input type="file" onChange={handleFileChange} />
                                        </div>
                                        <div>
                                            <button  onClick={handleUpload} className="mt-4 w-[160px] h-[45px] rounded-[12px] bg-gradient-to-r from-[#507C37] to-[#232323] text-white" >
                                                {
                                                    User?.loading ?
                                                    <ClipLoader color="white" size={22} />
                                                    :
                                                    "Update Photo"    
                                                }
                                                </button>
                                        </div>
                                        <p className='my-5' >*Photos depicting adult content, hateful imagery, illegal activities or copyrighted material are not allowed.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditPhotoModal;
