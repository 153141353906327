import { combineReducers } from "redux";
import { auth } from "../app/auth/auth.reducer";
import { chats } from "app/chats/chats.reducer";
import { user } from "app/user/user.reducer";
import { dashboard } from "app/dashboard/dashboard.reducer";
import { stripe } from "app/stripe/stripe.reducer";

const RootReducers = combineReducers({ auth , chats , user , stripe , dashboard});

export default RootReducers;
